@font-face{
  font-family:'Airbnb';
  src: url(./fonts/airbnb-cereal-app-book.ttf) format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style:normal
}

@font-face {
  font-family: 'Candal';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./fonts/candal-latin-400-normal.woff2) format('woff2'), url(./fonts/candal-latin-400-normal.woff) format('woff');
}

* {
	font-family: 'Airbnb';
}

#GLOBAL
*:focus-visible, *:focus {
	border-color: #F6AC37 !important;
	box-shadow: none !important;
}

*[data-focus], *[data-focus-visible] {
	box-shadow: none !important;
	border-color: #F6AC37 !important;
}

.rotate {
  animation: rotation 20s;
}

.linear {
  animation-timing-function: linear;
}

.infinite {
  animation-iteration-count: infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}



.block {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 272px;
	height: 272px;
	border-radius: 50%;
}

.box {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: calc(100% - 50px);
	height: calc(100% - 50px);
	border-radius: 50%;
}

.box::before {
	position: absolute;
	content: '';
	width: calc(100% + 28px);
	height: calc(100% + 28px);
	border-radius: 50%;
}

.dots {
	display: block;
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	transition: 2s transform, 2s opacity ease;
}

.dots::after {
	position: absolute;
	content: '';
	width: 10px;
	height: 10px;
	top: 3px;
	left: 50%;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 0 0 5px 2px #fff;
	transform: translateX(-50%);
}

.svg {
	position: absolute;
	width: 100%;
	height: 100%;
	fill: none;
	transform: scaleX(-1) rotate(-90deg);
}

.circle {
	stroke: url(#gradientStyle);
	stroke-width: 8px;
	stroke-dasharray: 803;
	stroke-dashoffset: 803;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
}

.label {
	transition-property: all !important;
    transition-timing-function: cubic-bezier(.4,0,.2,1) !important;
	transition: all !important;
	transition-duration: .2s !important;
}

.input.active {
	padding-top: 12px;
}

.label.active {
	color: #7B5312;
	font-size: 10px;
    font-weight: 700;
	top: 5px;
    left: 17px;
}


.container {
	margin: 0 auto;
	width: 500px;
	height: 200px;
	background-color: lightblue;
	position: relative;
	overflow: hidden;
}

.cardshape2:after {
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1);
    content: "";
    position: absolute;
    bottom: 0;
    right: 6px;
    left: 6px;
    bottom: -17px;
    height: 37px;
    background: #fff;
    clip-path: ellipse(79% 70% at 50% 100%);
}

.cardshape2.selected::after {
	background: #F6AC37;
}

.cardshape {
	position: relative;
	overflow: hidden;
	height: 30px;
  }
  
.cardshape::before {
	border-radius: 100%;
	position: absolute;
	right: -203px;
	left: -200px;
	top: -150px;
	content: '';
	bottom: calc(100% - 11px);
}

.cardshape.long::before {
	bottom: 60%;
	border-radius: calc(100% - 95px);
}

.cardshape.normal::before {
	background: white;
}

.cardshape.selected::before {
	background: #F6AC37;
}

.animate {
	transition-property: all !important;
    transition-timing-function: cubic-bezier(.4,0,.2,1) !important;
	transition: all !important;
	transition-duration: .2s !important;
}

.popup-bottom {
	position: relative;
	overflow: hidden;
	height: 20px;
	top: 17px;
	margin-left: 3px;
	margin-right: 3px;
}

/* .popup-bottom::before {
	border-radius: 100%;
	position: absolute;
	right: -203px;
	left: -200px;
	top: -150px;
	content: '';
	bottom: calc(100% - 11px);
	background: white;
} */


.popup-bottom:after {
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1);
    content: "";
    position: absolute;
    bottom: 0;
    right: 4px;
    left: 4px;
    bottom: 0px;
    height: 37px;
	background: white;
    clip-path: ellipse(79% 70% at 50% 100%);
}

.result-bottom-arc {
	position: relative;
	overflow: hidden;
	height: 20px;
	top: -2px;
	margin-left: 3px;
	margin-right: 3px;
	width: 100%;
}

.result-bottom-arc:after {
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1);
    content: "";
    position: absolute;
    bottom: 0;
    right: 9px;
    left: 9px;
    bottom: 0px;
    height: 37px;
    clip-path: ellipse(74% 70% at 50% 100%);
}

.result-bottom-arc.correct:after {
	background: #55D976;
}

.result-bottom-arc.wrong:after {
	background: #FF6961;
}

.result-top-arc {
	position: relative;
	overflow: hidden;
	height: 24px;
	top: 15px;
	margin-left: 3px;
	margin-right: 3px;
	width: 100%;
}

.result-top-arc:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 9px;
    left: 9px;
    bottom: -4px;
    height: 37px;
    clip-path: ellipse(74% 70% at 50% 100%);
}

.result-top-arc.correct:after {
	background: #55D976;
}

.result-top-arc.wrong:after {
	background: #FF6961;
}

.arrow {
	transition: transform 0.3s;
}

.up-arrow {
	transform: rotateX(0deg);
}

.down-arrow {
	transform: rotateX(180deg);
}

/* component */

.circular-pbar:after {
	position: absolute;
	border-radius: 50%;
	width: calc(100% - 10px);
	height: calc(100% - 10px);
	background: #000;
	content: '';
}

.circular-pbar {
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	position: relative;
	--p: 0;
	background: conic-gradient(rgba(242,242,242, 0.2) var(--p, 0), 0, #FFA031);
	width: 50px;
	height: 50px; 
	border-radius: 50%;
}

.circular-pbar-counter {
	color: white;
	background: transparent;
	z-index: 1;
	font-family: "Candal";
	font-size: 10px;
	font-weight: 400;
}